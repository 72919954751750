.connections-container {
  border: 8px solid #e3e5e8;
  border-radius: 2px;
  @media screen and (max-width: 767px) {
    .wishlist-accordion {
      padding: 0px;
      word-break: break-all;
      div {
        padding-right: 5px;
      }
    }
    .card-header {
      padding: 5px;
    }
    .card-header button {
      text-align: left;
      padding: 0px;
    }
    .connection-list-header .user-box {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      .remove-connection-link {
        font-weight: 0.7em;
      }
    }
  }
  .connection-list {
    padding-right: 0px;
    background: #e3e5e8;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .add-new-mobile {
      display: none;
    }
    .add-one-button {
      display: flex;
    }
    .list-group-item-primary {
      background: #1e6932;
      color: #efefef;
      font-weight: bold;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: row;
      .add-new-mobile {
        display: flex;
      }
      .add-new-desktop {
        display: none;
      }
      .list-group-item {
        display: flex;
        flex: 1 1 0px;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        border: 1px solid #e3e5e8;
      }
      .list-group-item-primary {
        flex-grow: 2;
      }
    }
  }
  .connection-wishlist {
    padding: 0px;
    border-left: 10px solid #e3e5e8;
    background: #e3e5e8;
  }
}
