.connection-list-header {
  background: #1e6932;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
  }
  .user-box {
    padding-left: 10px;
    color: #efefef;
    align-items: baseline;
    .connection-name {
      font-size: 1.1em;
      font-weight: bold;
      margin-right: 4px;
    }
    .remove-connection-link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
