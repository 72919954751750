.gift-display-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  div {
    display: flex;
  }
  .gift-header-row {
    flex-direction: row;
    padding-bottom: 10px;
    align-items: flex-start;
    .gift-title-description {
      flex-direction: column;
      flex-grow: 2;
      max-width: 94%;
      .gift-title {
        font-weight: bold;
        font-size: 1.5em;
        max-width: 100%;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }
      .gift-description {
        padding: 5px;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }
    }
    .edit-button-icon-container {
      margin-top: 10px;
      padding: 0px;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      background: #ccc;
    }
  }
  .gift-body {
    .gift-body-column {
      flex-direction: column;
      .gift-property {
        padding: 5px;
      }
      .gift-property-contents {
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }
      .gift-property-title {
        font-weight: bold;
        padding-right: 4px;
      }
    }
    .gifts-links-list {
      ul {
        padding-left: 20px;
        max-width: 100%;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }
    }
  }
  .gift-footer {
    flex-direction: row;
    padding-top: 20px;
    .gift-footer-column {
      flex-direction: column;
      flex-grow: 1;
    }
    .gift-footer-right {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .btn-link {
      background: none;
      outline: none;
      border: none;
      text-decoration: underline;
    }
  }
  .gift-delete-button {
    margin-top: 10px;
    justify-content: center;
    background: #ccc4c4;
    padding: 15px;
  }
}
