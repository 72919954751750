.links-display {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: row;
  }
  .link-title-row {
    flex-grow: 1;
    .link-titles {
      flex-grow: 1;
    }
  }
  .links-collection {
    flex-direction: column;
  }
  .link-list-container {
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    .link-col-display {
      flex-grow: 1;
      flex: 1 1 0px;
      //padding: 5px;
      .link-remove-text {
        padding: 5px;
        text-decoration: line-through;
      }
    }
  }
  .link-remove-display {
    width: 30px;
    height: 30px;
    padding: 2px;
    margin-right: 2px;
    background: none;
    border: none;
  }
  .link-add-button-container {
    flex-grow: 1;
    justify-content: flex-end;
    padding: 5px;
    padding-bottom: 0px;
  }
}
