body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-breadcrumb {
  background: none;
  border: none;
}

.dashboard-button {
  margin: auto;
}

.dashboard-button-container {
  display: inline-block;
  padding: 10px;
}

.app-container {
  padding-bottom: 20px;
}

.app-spinner {
  margin-top: 10px;
}

.wishlist-accordion {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.shopping-cart-item {
  width: 100%;
  display: flex;
  padding-left: 5px;
  justify-content: space-between;
  .wishlist-icon {
    padding-right: 5px;
  }
}
.wishlist-icon {
  width: 30px;
  height: 30px;
}
.card .card-header button .wishlist-icon {
  min-width: 30px;
  min-height: 30px;
}

.clickable-group {
  .list-group-item {
    cursor: pointer;
  }
}
